<script>
import Vue, { ref, computed, onBeforeUnmount, nextTick } from 'vue'
import { sleep } from '/~/utils/timer'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseCard from '/~/components/base/card/base-card.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import DrawerActions from '/~/components/drawer/components/actions/drawer-actions.vue'
import RenderBarcode from '/~/components/render/render-barcode.vue'
import { useSwiper } from '/~/composables/base/use-swiper'
import { useLoyaltyCards } from '/~/composables/loyalty-cards'
import LoyaltyCard from './components/loyalty-card.vue'

export default {
  name: 'drawer-loyalty-details',
  components: {
    RenderBarcode,
    LoyaltyCard,
    BaseImage,
    DrawerActions,
    BaseCard,
    BaseAsidePage,
    BaseIcon,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      default: 'menu-modal',
    },
    close: {
      type: Function,
      default: null,
    },
  },
  setup(props, { emit }) {
    const {
      getLoyaltyCardById,
      markLoyaltyCardAsViewed,
      deleteLoyaltyCard,
      isLoyaltyCardsLoading,
    } = useLoyaltyCards()

    const loyaltyCard = ref(null)
    const isDeleting = ref(false)

    const swiper = ref(null)
    const swiperId = ref(Date.now())

    const {
      swiperContainer,
      slidesCount,
      activeIndex,
      initSwiper,
      destroySwiper,
    } = useSwiper(
      computed(() => ({
        centeredSlides: true,
        slidesPerView: 'auto',
        navigation: {
          nextEl: `[data-id="${swiperId.value}"][data-button="next"]`,
          prevEl: `[data-id="${swiperId.value}"][data-button="prev"]`,
          disabledClass: 'opacity-0 cursor-default',
        },
        effect: 'coverflow',
        coverflowEffect: {
          rotate: 0,
          stretch: -38,
          depth: 200,
          modifier: 2,
          slideShadows: true,
        },
      }))
    )

    getLoyaltyCardById(props.id).then(async (card) => {
      if (card) {
        loyaltyCard.value = card
        markLoyaltyCardAsViewed(card.id)
        await sleep(0)
        initSwiper()
      } else {
        emit('missed')
      }
    })

    const isViewReady = computed(() => {
      return Boolean(!isLoyaltyCardsLoading.value && loyaltyCard.value)
    })

    const isDisabled = computed(() => {
      return isDeleting.value
    })

    const actions = computed(() => {
      return [
        {
          label: 'Edit',
          description: 'Edit the details or images of your card',
          icon: 'plain/edit2',
          click: () => {
            emit('edit', props.id)
          },
        },
        {
          label: 'Delete',
          description: 'Delete this loyalty or member card',
          icon: 'plain/trash',
          type: 'danger',
          confirm: {
            text: 'Are you sure?',
            progressText: 'Deleting...',
            async submit() {
              isDeleting.value = true

              try {
                await deleteLoyaltyCard(props.id)
                Vue.notify({
                  text: 'Card Deleted',
                  type: 'success',
                  duration: 1000,
                })
                emit('delete')
              } catch (error) {
                console.error(error)
              } finally {
                isDeleting.value = false
              }
            },
          },
        },
      ]
    })

    onBeforeUnmount(destroySwiper)

    return {
      actions,
      loyaltyCard,
      isViewReady,
      isDisabled,
      swiper,
      swiperId,
      slidesCount,
      activeIndex,
      swiperContainer,
      getLoyaltyCardById,
      destroySwiper,
      initSwiper,
    }
  },
  watch: {
    '$route.hash': {
      async handler(newHash) {
        if (!newHash.startsWith('#profile-loyalty-card-details')) {
          return
        }

        this.destroySwiper()
        this.loyaltyCard = await this.getLoyaltyCardById(this.loyaltyCard.id)
        await sleep(0)
        this.initSwiper()
      },
    },
  },
  methods: {
    setFocus() {
      nextTick(() => {
        this.$refs.baseAsidePage.setFocus()
      })
    },
  },
}
</script>

<template>
  <base-aside-page
    ref="baseAsidePage"
    :title="loyaltyCard ? loyaltyCard.name : 'Loyalty Card'"
    no-padding
  >
    <div v-if="isViewReady" class="overflow-hidden pt-[30px]">
      <div
        v-if="loyaltyCard.hasManyImages"
        ref="swiperContainer"
        class="swiper-container my-5 w-full"
      >
        <div class="swiper-wrapper flex items-center">
          <loyalty-card
            class="swiper-slide max-w-xs overflow-hidden rounded-xl bg-strong"
            :card="loyaltyCard"
          />
          <base-card
            v-if="loyaltyCard.frontImage.src"
            class="swiper-slide max-w-xs overflow-hidden rounded-xl bg-strong"
          >
            <base-image
              v-bind="loyaltyCard.frontImage"
              :alt="loyaltyCard.name"
            />
          </base-card>
          <base-card
            v-if="loyaltyCard.backImage.src"
            class="swiper-slide max-w-xs overflow-hidden rounded-xl bg-strong"
          >
            <base-image
              v-bind="loyaltyCard.backImage"
              :alt="loyaltyCard.name"
            />
          </base-card>
        </div>
        <div
          :data-id="swiperId"
          data-button="prev"
          role="button"
          class="absolute top-1/2 z-10 ml-5 -translate-y-1/2 transform rounded-full bg-white p-[5px] md:flex"
        >
          <base-icon svg="chevron-left" size="md" />
        </div>
        <div
          :data-id="swiperId"
          data-button="next"
          role="button"
          class="absolute right-0 top-1/2 z-10 mr-5 -translate-y-1/2 transform rounded-full bg-white p-[5px] md:flex"
        >
          <base-icon svg="chevron-right" size="md" />
        </div>
      </div>

      <div v-else class="text-md relative mx-auto w-full max-w-xs">
        <loyalty-card :card="loyaltyCard" />
      </div>
      <div class="px-5">
        <render-barcode
          v-if="loyaltyCard.barcode"
          class="mt-[30px]"
          :barcode="loyaltyCard.barcode"
        />
        <drawer-actions :actions="actions" :disabled="isDisabled" />
      </div>
    </div>
    <div v-else class="pt-[30px]">
      <div class="text-md relative mx-auto w-full max-w-xs">
        <base-card class="animate-pulse rounded-xl bg-strong" />
      </div>
    </div>
  </base-aside-page>
</template>
